const $ = jQuery.noConflict();
var MatchHeight = require("./match-height.js");
var MatchRow = require("./match-row.js");
var Maps = require("../components/maps.google.js");
var Slider = require("../modules/slider.slick.js");

var self = (module.exports = {
  props: {
    method: "GET",
    url: ajax.ajaxurl,
    dataType: "json",
    contentType: false,
    processData: false,
  },

  init: function (selector) {
    self.events.change(selector);
    self.events.page("[data-page]");
    self.events.submit(selector);
    self.events.navigate();
  },

  events: {
    change: function (form) {
      $(form).on(
        "change",
        "input[type=radio], input[type=checkbox], input[type=text], select",
        function () {
          console.log("on change called...");
          var page = $(form).find('[name="page"]');
          $(page).val(1);
        }
      );
    },

    page: function (trigger) {
      $(document).on("click", trigger, function (e) {
        var parent = $(this).closest("[data-target]");
        var form = $(parent).data("target");
        var page = $(form).find('[name="page"]');
        var next = parseInt($(this).data("page"));
        e.preventDefault();

        console.log("it is me..", next);

        $(this).data("page", parseInt(next) + 1);
        $(page).val(next);
        $(form).trigger("submit");
      });
    },

    submit: function (parent) {
      $(document).on("submit", parent, function (e) {
        var form = $(this);
        e.preventDefault();

        if ($(form).hasClass("submitting")) {
          return;
        }
        console.log("submit pressed...");
        var data = {};
        var target = $(form).data("target");
        var content = $(form).data("content");
        var pagination = $(form).data("pagination");
        var page = $(form).find('[name="page"]');
        var submit = $(form).find('[type="submit"]');
        var postType = $(form).find('[name="type"]');

        console.log(target);
        $(form).addClass("submitting");
        $(target).removeClass("loaded");

        console.log("page:", $(page).attr("value"));
        //console.log('postType:', postType)
        if (postType === "property" || parseInt($(page).attr("value")) === 1) {
          $(target).html("");
        }

        //$(page).attr('value', parseInt($(page).attr('value'))+1)
        //$(pagination).find('button').attr('data-page', parseInt($(page).attr('value'))+1)
        //$(pagination).find('span').html('')
        $(submit).prop("disabled", true);

        if (parseInt($(page).attr("value")) === 1) {
          $(target).removeClass("active");
        }

        self.service.data(form, data);
        data = $.param(data);
        var query = "?" + data;
        history.pushState(query, null, query);
        self.service.updateLinks(query);
        self.service.post(form, data);
        /*if(target) {
          $('html, body').animate({
            scrollTop: $(target).offset().top
          }, 200)
        }

        $(form).addClass('submitting')
        $(target).removeClass('loaded')
        $(target).html('')
        $(pagination).find('ul').html('')
        $(submit).prop('disabled', true)

        if ($(page).attr('value') == 1) {
          $(target).removeClass('active')
        }

        self.service.data(form, data)
        data = $.param(data)
        var query = '?' + data
        history.pushState(query, null, query)
        self.service.post(form, data)*/
      });
    },

    navigate: function () {
      window.addEventListener("popstate", function (e) {
        location.reload(true);
      });
    },
  },

  service: {
    data: function (form, data) {
      $(form)
        .find("input, textarea, select")
        .each(function () {
          var name = $(this).attr("name");
          var value = $(this).val();

          if ($(this).is(":checkbox")) {
            if ($(this).prop("checked")) {
              if (typeof data[name] !== "undefined") {
                data[name].value += ", " + value;
              } else {
                data[name] = value;
              }
            }
          } else if ($(this).is(":radio")) {
            if ($(this).prop("checked")) {
              data[name] = value;
            }
          } else {
            data[name] = value;
          }
        });
      return data;
    },

    post: function (form, data) {
      $.ajax({
        method: self.props.method,
        url: self.props.url,
        data: data,
        dataType: self.props.dataType,
        contentType: self.props.contentType,
        processData: self.props.processData,
        success: function (response) {
          self.service.show(response, form);
        },
        error: function (data) {
          console.log(data);
        },
      });
    },

    show: function (response, form) {
      var target = $(form).data("target");
      var pagination = $(form).data("pagination");
      var count = $(form).data("count");
      var submit = $(form).find('[type="submit"]');
      var button = $(form).data("button");
      var page = $(form).find('[name="page"]');

      //$(target).html(response.html)
      // $(target).append(response.html)
      console.log("page:", $(page).attr("value"));
      if ($(page).attr("value") === 1) {
        $(target).html(response.html);
      } else {
        $(target).append(response.html);
      }

      $(".none__property").html("");

      $(button).removeClass("display-none");
      console.log("pagination...", pagination);
      if (response.max == response.page) {
        $(pagination).hide();
      }

      if (count && response.total == 0) {
        $(count).html("");
      } else if (count) {
        $(count).html(response.count);
      }

      var map = $(target).find(".map");
      var slick = $(target).find(".slick");

      if (map) {
        $(map).each(function () {
          Maps.service.initMap(this);
        });
      }

      if (slick.length) {
        $(slick).each(function () {
          SLICK.service.init(this);
        });
      }

      // $('[data-match-height]').matchHeight()
      // $('[data-match-row]').matchRow()

      MatchHeight.init("[data-match-height]");
      MatchRow.init("[data-match-row]");
      $(target).addClass("loaded");
      $(form).removeClass("submitting");
      $(submit).prop("disabled", false);
    },

    updateLinks: function (query) {
      var propertyPage = $("[name=property-page]").val();
      var mapPage = $("[name=map-page]").val();

      var gridLink = $('[data-view="grid"]');
      var listLink = $('[data-view="list"]');
      var mapLink = $('[data-view="map"]');

      $(gridLink).attr(
        "href",
        propertyPage +
          query
            .replace("view=map", "view=list")
            .replace("view=list", "view=grid")
      );
      $(listLink).attr(
        "href",
        propertyPage +
          query
            .replace("view=map", "view=grid")
            .replace("view=list", "view=list")
      );
      // $(mapLink).attr('href', mapPage + query.replace('view=grid', 'view=map').replace('view=list', 'view=map'))
    },

    submit: function (form) {
      $(form).submit();
    },
  },
});
